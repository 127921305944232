import React from "react";
import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Container,
  Heading,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Link } from "gatsby";

import Layout from "../components/Layout";

const TaxonomyTemplate = (props) => {
  const { pageContext } = props;
  const { label, link, options } = pageContext;

  const taxonomyList = (
    <Center paddingTop="100px" height="500px">
      <Container maxW="7xl" borderWidth="1px" borderRadius="lg" p="2em">
        <Breadcrumb marginBottom=".5em">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/journals/biology">
              Biology
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>{label}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading size="lg" color="gray" marginBottom=".5em">
          {label}
        </Heading>
        <Wrap>
          {options.map((option) => (
            <Tooltip label={option.description}>
              <WrapItem key={option.value}>
                <Link to={`${link}${option.value}`}>
                  <Box as={Button} variant="outline" size="lg">
                    <Avatar size="sm" name={option.label} />
                    <Text paddingLeft="1">{option.label}</Text>
                  </Box>
                </Link>
              </WrapItem>
            </Tooltip>
          ))}
        </Wrap>
      </Container>
    </Center>
  );

  return <Layout children={taxonomyList} />;
};

export default TaxonomyTemplate;
